import { ArrowRightIcon, ArrowLeftIcon } from "~/old-app/assets/icons";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Pagination({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  table = false,
  isLoading,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [pageNumbers, setPageNumbers] = useState([]);
  const [startPage, setStartPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [endPage, setEndPage] = useState(1);

  useEffect(() => {
    const calculatePageNumbers = () => {
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      const maxPageNumbersToShow = 5;
      const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

      let startPage = Math.max(1, currentPage - halfMaxPageNumbersToShow);
      let endPage = Math.min(
        totalPages,
        currentPage + halfMaxPageNumbersToShow
      );

      if (currentPage <= halfMaxPageNumbersToShow) {
        endPage = Math.min(totalPages, maxPageNumbersToShow);
      }

      if (currentPage + halfMaxPageNumbersToShow >= totalPages) {
        startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
      }

      const pageNumbers = [];
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      return { pageNumbers, startPage, endPage, totalPages };
    };

    const { pageNumbers, startPage, endPage, totalPages } =
      calculatePageNumbers();

    setPageNumbers(pageNumbers);
    setStartPage(startPage);
    setEndPage(endPage);
    setTotalPages(totalPages);
  }, [totalItems, itemsPerPage, currentPage]);
  return (
    <div className={`flex my-8 items-center justify-center `}>
      <div
        className={`flex ${
          !table
            ? ""
            : "border border-solid border-gray-80 rounded overflow-hidden"
        }`}
      >
        <button
          onClick={() => {
            paginate(currentPage - 1);
          }}
          className={`flex gap-3 items-center text-sm font-semibold text-gray-700 ${
            currentPage === 1 ? "cursor-not-allowed" : "hover:underline"
          } ${table ? "py-2 px-3" : "rounded-r-md"} ${
            currentPage === 1 ? "" : ""
          }`}
          disabled={currentPage === 1 || isLoading}
        >
          {/* {t("global.prev")} */}
          {language !== "ar" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </button>

        {startPage > 1 && (
          <React.Fragment>
            <button
              onClick={() => {
                paginate(1);
              }}
              className={`text-sm font-semibold text-gray-700 ${
                currentPage === 1 ? "bg-primary-60" : ""
              } ${table ? "py-2 px-3" : "rounded-md"} ${
                currentPage === 1 ? "bg-primary-60" : ""
              }`}
              disabled={isLoading}
            >
              1
            </button>
            {startPage > 2 && <span className="px-2">...</span>}
          </React.Fragment>
        )}
        {pageNumbers.map((number) => (
          <React.Fragment key={number}>
            <button
              onClick={() => {
                paginate(number);
              }}
              disabled={isLoading}
              className={`text-sm font-semibold text-gray-700 ${
                currentPage === number
                  ? table
                    ? "bg-primary-500 text-white"
                    : "underline"
                  : ""
              } ${table ? "py-2 px-3" : "rounded-md"} ${
                currentPage === number ? "bg-primary-60" : ""
              }`}
            >
              {number}
            </button>
          </React.Fragment>
        ))}
        {endPage < totalPages && (
          <React.Fragment>
            {endPage < totalPages - 1 && <span className="px-2">...</span>}
            <button
              onClick={() => {
                paginate(totalPages);
              }}
              disabled={isLoading}
              className={`text-sm font-semibold text-gray-700 ${
                currentPage === totalPages ? "bg-primary-60" : ""
              } ${table ? "py-2 px-3" : "rounded-md"} ${
                currentPage === totalPages ? "bg-primary-60" : ""
              }`}
            >
              {totalPages}
            </button>
          </React.Fragment>
        )}
        {/* }}
              className={`text-sm font-semibold text-gray-700 ${
                currentPage === number
                  ? table
                    ? "bg-primary-60"
                    : "underline"
                  : ""
              } ${table ? "py-2 px-3" : "rounded-md"} ${
                currentPage === number ? "bg-primary-60" : ""
              }`}
            >
              {number}
            </button>
          </React.Fragment>
        ))} */}
        <button
          onClick={() => {
            paginate(currentPage + 1);
          }}
          className={`flex gap-3 items-center text-sm font-semibold text-gray-700 ${
            currentPage === pageNumbers.length
              ? "cursor-not-allowed"
              : "hover:underline"
          } ${table ? "py-2 px-3" : "rounded-r-md"} ${
            currentPage === pageNumbers.length ? "" : ""
          }`}
          disabled={currentPage === pageNumbers.length || isLoading}
        >
          {/* {t("global.next")} */}
          {language === "ar" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </button>
      </div>
    </div>
  );
}
